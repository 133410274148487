import { Injectable } from '@angular/core';

@Injectable()
export class UtilityService {
    tagList = [];

    constructor() {

    }

    makeArrayElementChangeDetectable(arr: Array<any>) {
        for (let i in arr) {
            if (arr[i].val === undefined)
                arr[i] = { val: arr[i] };
        }
    }

    reverseToParseableArr(arr: Array<any>) {
        for (let i in arr) {
            if (arr[i].val)
                arr[i] = arr[i].val;
        }
    }

    formatOptionList(optionListObj: any) {
        const optList = optionListObj.optionList.map(el => el.val);
        return {
            correctAnswer: optionListObj.correctAnswer,
            optionList: optList
        };
    }

    formatRefLinks(refLinksObj: any) {
        const refLinks = refLinksObj.refList.map(el => el.val);
        return {
            refList: refLinks.filter(ref => {
                if(ref && ref!==null){
                    return true;
                } else {
                    return false;
                }
            })
        };
    }

    //Alternative to Object.assign(), since it performs shallow copy
    //(Note: This bypasses one level of shallow copying  )
    clone(cloneable: any) {
        let objCopy = {}; // objCopy will store a copy of the mainObj
        let key;
        if (typeof cloneable === 'object') {
            for (key in cloneable) {
                if (typeof cloneable[key] === 'object') {
                    objCopy[key] = Object.assign({}, cloneable[key]);
                } else {
                    objCopy[key] = cloneable[key]; // copies each property to the objCopy object
                }
            }
            return objCopy;
        }
    }

    set tagListData(data) {
        this.tagList = data;
    }

    get tagListData() {
       return this.tagList;
    }
}