export const API = {
   //BASE_URL: 'http://localhost:4444/api/v1',
    BASE_URL: 'https://api.qbank.medlearnity.com/api/v1',
    USER: {
        SIGNIN: '/account/signin',
        SIGNUP: '/account/signup',
        RESET_PASSWORD: '/account/resetPassword',
        RESET_PASSWORD_LINK: '/account/resetPasswordLink',
        GET_USERS: '/account/getUsers',
        VERFIY_USER: '/account/verify/',
        UPDATE_USER: '/account/updateUser/',
        DELETE_USER: '/account/deleteUser/',
        REFRESH_TOKEN: '/auth/token',
        GET_QUESTION_VIEWS: '/account/getViews',
        UPDATE_QUESTION_VIEWS: '/account/updateViews'
    },
    QUESTION: {
        CREATE: '/question/createQuestion',
        UPDATE: '/question/updateQuestion/',
        QUESTIONS: '/question/getQuestions',
        QUESTION_BY_ID: '/question/getQuestionById/',
        REMOVE_QUESTION_: '/question/removeQuestionById',
        UPDATE_PRESENTATION_COUNT: '/question/updatePresentationCount/'
    },
    IMAGE: {
        UPLOAD: '/upload/images',
        GET_IMAGE: '/image/getImageByName',
        DOWNLOAD_IMAGE: '/image/downloadImageByName'
    },
    PRESENTATION: {
        CREATE: '/presentation/createPresentation/',
        UPDATE: '/presentation/updatePresentation',
        GET_PRES_BY_TUTORID: '/presentation/getPresentationsViaQId/',
        GET_USABLE_COUNT:'/presentation/getUsablePresentationCount/'
    },
    MINI_TOPIC:{
        CREATE:'/mini-topic/createMiniTopicPresentation',
        GET: '/mini-topic/getMiniTopicPresentations'
    },
    TAGS: {
        GET: '/tag/getTags',
        GET_QUES_TAG: '/tag/getQuestionTags',
        UPDATE_QUES_TAG: '/tag/updateQuestionTags'
    }
}

export const USER_TYPE = {
    TUTOR: 'tutor',
    ADMIN: 'admin',
    SUPER_ADMIN: 'superAdmin',
    EDITOR: 'editor'
}

export const DIFFICULTY_TYPE = ['EASY', 'MEDIUM', 'HARD'];
export const CONFIRM_CHANGES = 'Confirm Changes';
export const REMOVE_WARNING_MESSAGE = 'Are you sure you want to delete this ?'
export const DEFAULT_ERROR_MESSAGE = 'Ooops an error occured';
export const PRESENTATION_DEFAULTS = {
    RATING: 0
};
export const NO_QUESTIONS = "You didn't add any question yet";
export const MAX_QUESTION_VIEW_COUNT = 50;
export const MIN_OPTION_COUNT = 5;
export const LESS_OPTION_COMMNENT = "Please add a comment if you are submitting less than 5 options";
