import { Component, OnInit, Input } from '@angular/core';
import { UtilityService } from '../../services/utility.service';
@Component({
  selector: 'reference-links',
  templateUrl: './reference-links.component.html',
  styleUrls: ['./reference-links.component.css']
})
export class ReferenceLinksComponent implements OnInit {
  @Input() ref;
  @Input() title;
  @Input() isReadOnly = false;
  public links = [{ val: '' }]
  constructor(utilityService: UtilityService) { }

  ngOnInit() {
  }
  ngOnChanges(changes) {
    this.ref = changes.ref.currentValue;
    this.initRefLinks();
  }

  private initRefLinks() {
    if (!this.ref.refList || this.ref.refList.length === 0) {
      this.ref.refList = this.links;
    }
  }

  addLink(event) {
    event.preventDefault();
    this.ref.refList = [...this.ref.refList, { val: '' }];
  }

  removeLink(index, event) {
    event.preventDefault();
    this.ref.refList = index !== this.ref.refList.length - 1 ?
      [...this.ref.refList.slice(0, index), ...this.ref.refList.slice(index + 1)] :
      [...this.ref.refList.slice(0, index)];
  }

}
