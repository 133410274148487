import { Component } from '@angular/core';
import { UserAuthService } from '../../services/userauth.service';
import { AlertService } from '../../services/alert.service';
import { Location } from '@angular/common';
import { Router } from '@angular/router';
import { USER_TYPE } from '../../constants/app.constant';
import { LoaderService } from '../../services/loader.service';

@Component({
    selector: 'app-signup',
    templateUrl: './signup.component.html',
    styleUrls: ['./signup.component.css']
})
export class SignupComponent {

    showSpinner: boolean = false;
    username: string;
    password: string;
    userType: string;
    userTypes: Array<string> = [USER_TYPE.TUTOR];
    constructor(private router: Router, private location: Location, private userAuthService: UserAuthService, private alertService: AlertService, private loaderService: LoaderService) {

    }

    onSubmit(event, signupForm) {
        event.preventDefault();
        if(this.username.indexOf('medlearnity.com') === -1){
            this.alertService.notifyUser({ status: false, message: 'Sorry! Only users with medlearnity email are allowed.' });
            return false;
        }
        this.loaderService.show();
        this.userAuthService.userSignUp({ email: this.username, password: this.password, type: this.userType }).subscribe((data) => {
            this.loaderService.hide();
            if (parseInt(data['status'])) {
                this.alertService.notifyUser({ status: true, message: 'please verify your email and login' }, /*on modal close callback*/() => {
                    this.postSignup(data['result'].data);
                });
            }
            else {
                this.alertService.notifyUser({ status: false, message: data['result'].message });
                this.showSpinner = false;
            }
        }, err => {
            this.loaderService.hide();
            this.alertService.notifyUser({ status: false, message: err.message });
        });
        signupForm.reset();
    }

    postSignup(userData: any) {
        //const user = new User(userData.userId, userData.email, userData.type);
        this.router.navigate([""]);
        this.location.replaceState('');

    }
    gotoPrevState() {
        this.location.back();

    }
}
