import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { PRESENTATION_DEFAULTS } from '../../constants/app.constant'

@Component({
  selector: 'rating',
  templateUrl: './rating.component.html',
  styleUrls: ['./rating.component.css']
})
export class RatingComponent implements OnInit {

  rating: number = PRESENTATION_DEFAULTS.RATING;
  @Output() ratingClick: EventEmitter<any> = new EventEmitter<any>();

  inputName: string;
  ngOnInit() {
    this.inputName = '0_rating';
  }
  onClick(rating: number): void {
    this.rating = rating;
    this.ratingClick.emit({
      rating: rating
    });
  }
}
