import { Component, OnInit, Optional, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material';
import { MiniTopicService } from '../../services/mini-topic.service';
import { AlertService } from '../../services/alert.service';
@Component({
  selector: 'app-mini-topic-log',
  templateUrl: './mini-topic-log.component.html',
  styleUrls: ['./mini-topic-log.component.css']
})
export class MiniTopicLogComponent implements OnInit {

  modalData: any;
  rowData: any;
  columnDefs = [];
  GridOptions={
    enableColResize: true,
    onGridReady: e => {
      if (!e || !e.api) {
          return;
      }
      e.api.sizeColumnsToFit();
      e.api.setGridAutoHeight(true);
  }
  };
  constructor(@Optional() @Inject(MAT_DIALOG_DATA) public data: any, private miniTopicSvc: MiniTopicService) {
    this.columnDefs = [
      { headerName: 'miniTopic id', field: '_id', sortable: true, filter: true },
      { headerName: 'mini Topic', field: 'title', sortable: true, filter: true },
      { headerName: 'Date/Time', field: 'updatedAt', sortable: true, filter: true }
    ];
    this.modalData = data;
  }

  ngOnInit() {
    this.rowData = this.miniTopicSvc.fetchMiniTopics();
  }

}
