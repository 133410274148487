import { Injectable } from '@angular/core';
import { APIService } from './api.service';
import { API } from '../constants/app.constant';
import { catchError, map } from 'rxjs/operators';
@Injectable({
  providedIn: 'root'
})
export class MiniTopicService {

  constructor(private api: APIService) { }

  fetchMiniTopics() {
    return this.api.get(API.BASE_URL + API.MINI_TOPIC.GET).pipe(
      map((resp) => {
        if (resp['status'] === '1') {
          return resp['result'].data;
        } else {
          throw new Error(resp['result'].message)
        }
      }),
      catchError((err) => {
        throw err;
      })
    );
  }

  createMiniTopic(topicObj) {
    return this.api.post(API.BASE_URL + API.MINI_TOPIC.CREATE, topicObj).pipe(
      map((resp) => {
        if (resp['status'] === '1') {
          return resp['result'].data;
        } else {
          throw new Error(resp['result'].message)
        }

      }),
      catchError((err) => {
        throw err;
      })
    )
  }

}

