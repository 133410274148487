

export interface Question {
    qId: any;
    userId: string;
    userEmail: string;
    stem: string;
    image: {
        imageList: string[],
        imageForm: FormData[]
    };

    option: {
        optionList: any,
        correctAnswer
    };
    explanation: string,
    additionalExplanation: string,
    comment?: string,
    reference: {
        refList: Array<string>
    };
    videoLinks: {
        refList: Array<string>
    };
    googleDocLinks: {
        refList: Array<string>
    };
    categories: {
        discipline: Array<any>,
        organSystem: Array<any>,
        examType: Array<any>
    }
    contentFeedback: {
        hasIssue: Boolean,
        isResolved: Boolean
    }
    createdAt?: Date;
    presentationCount: number;
    usablePresentationCount: number;
}


export class Question implements Question {


    constructor(userId?, qId?, stem?, image?, option?, explanation?, additionalExplanation?,
        reference?, videoLinks?, googleDocLinks?, categories?, userEmail?, createdAt?, presentationCount?,
        usablePresentationCount?, contentFeedback?, comment?) {
        this.userId = userId;
        this.userEmail = userEmail;
        this.qId = qId || null;
        this.stem = stem || '';
        this.image = image || null;
        this.option = option || null;
        this.explanation = explanation || '';
        this.additionalExplanation = additionalExplanation || '';
        this.reference = reference || null;
        this.videoLinks = videoLinks || null;
        this.googleDocLinks = googleDocLinks || null;
        this.categories = categories || null;
        this.createdAt = createdAt || null;
        this.presentationCount = presentationCount || 0;
        this.usablePresentationCount = usablePresentationCount || 0;
        this.contentFeedback = contentFeedback || null;
        this.comment = comment || null;
    }
}