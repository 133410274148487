import { Component, OnInit, Input, ViewEncapsulation, Output, EventEmitter } from '@angular/core';
import { ImageViewerComponent } from '../image-viewer/image-viewer.component';
import { ImageManagerService } from './../../services/image-manager.service';
import { MatDialog } from '@angular/material';
import { catchError, map } from 'rxjs/operators';

@Component({
  selector: 'app-image-preview',
  templateUrl: './image-preview.component.html',
  styleUrls: ['./image-preview.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class ImagePreviewComponent implements OnInit {
  @Input() images = [];
  @Output() updatedImageList = new EventEmitter<any>();

  previewImages = [];
  constructor(private imgSvc: ImageManagerService, private matDialog: MatDialog) { }

  ngOnInit() {
  }

  ngOnChanges(changes) {
    if (changes.images) {
      this.getImagestoDisplay(changes.images.currentValue);
    }
  }

  previewImage(index: string) {
    const dialogRef = this.matDialog.open(ImageViewerComponent, {
      width: '60%',
      panelClass: 'image-viewer-modal',
      data: {
        url: this.previewImages[index].thumbImage
      },
      disableClose: false
    });


    dialogRef.afterClosed().subscribe(isDelete => {
      if (isDelete) {
        this.images.splice(Number(index), 1);
        this.previewImages.splice(Number(index), 1);
        this.updatedImageList.emit({
          fileImg: this.images,
          prevImages: this.previewImages
        });
      }
    });
  }

  getBase64(file) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = reject;
    });
  }

  async getImagestoDisplay(images) {
    let imgArr = [], i = 0;

    try {
      for (let image of images) {
        if (image.name) {
          imgArr[i] = { thumbImage: await this.getBase64(image) };
        } else {
          imgArr[i] = {
            thumbImage: await new Promise(resolve => {
              this.imgSvc.fetchImage(image)
                .subscribe(
                  (resp: any) => resolve(resp.imgData),
                  (err) => resolve('assets/images/no-image.jpg'));
            })
          };
        }

        i++;
      }
    }

    catch (err) {
      console.error("No image found");
    }

    this.previewImages = imgArr;
  }

}
