import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { ActivatedRoute } from "@angular/router";
import { UserAuthService } from '../../services/userauth.service';
import { AlertService } from '../../services/alert.service';
import { FormControl, FormGroup, Validators, NgForm } from '@angular/forms';


@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.css']
})
export class ResetPasswordComponent implements OnInit {


  showSpinner: boolean = false;
  isLinkValidation: boolean = false;
  userId: string;
  public resetForm: FormGroup;
  constructor(private router: ActivatedRoute, private location: Location, private userAuthService: UserAuthService, private alertService: AlertService) {

  }
  ngOnInit() {
    this.userId = this.router.snapshot.params.userId;
    this.resetForm = new FormGroup({
      email: new FormControl('', [Validators.required]),
      password: new FormControl('', [Validators.required])
    });
  }

  onResetLinkSend(email: string) {
    if (email) {
      this.userAuthService.resetPasswordLink(email)
        .subscribe(data => {
          this.alertService.notifyUser({ status: false, message: "Successfully sent RESET Password mail" });
          this.showSpinner = false;
        }, err => {
          this.alertService.notifyUser({ status: false, message: err });
          this.showSpinner = false;
        });
    } else {
      this.alertService.notifyUser({ status: false, message: 'Email not sent' });
      this.showSpinner = false;
    }
  }
  onResetPassword() {
    this.showSpinner = true;
    const email = this.resetForm.value.email;
    const newPassword = this.resetForm.value.password;
    if (this.userId && !!newPassword) {
      this.userAuthService.resetPassword(this.userId, { password: newPassword })
        .subscribe(
          data => {
            this.alertService.notifyUser({ status: true, message: "Successfully RESET Password" });
            this.showSpinner = false;
          }, err => {
            this.alertService.notifyUser({ status: false, message: err });
            this.showSpinner = false;
          });
    } else if (!this.userId && email) {
      this.onResetLinkSend(email);
    }
    else {
      this.alertService.notifyUser({ status: false, message: 'User Id required' });
      this.showSpinner = false;
    }
  }
  gotoPrevState() {
    this.location.back();
  }

}
