import { Injectable } from '@angular/core';
import { User } from '../models/user.model';
@Injectable()
export class UserService {
    currentUser: User;
    totalQuestionsViewsRemaining: number = 0;
    constructor() { }
    setCurrentUser(user: User) {
        this.currentUser = user;
        localStorage.setItem('c_user', JSON.stringify(user));
    }
    getCurrentUser(): any {
        if (!this.currentUser) return this.currentUser = JSON.parse(localStorage.getItem('c_user'));
        return this.currentUser;
    }
    removeCurrentUser() {
        localStorage.removeItem('c_user');
        this.currentUser = null;
    }
    getCurrentUserToken(): string | null {
        let token, currentUser = this.getCurrentUser();
        if (currentUser)
            return token = currentUser.token;
        return null;
    }
    getCurrentUserRefreshToken(): string {
        return this.currentUser['refreshToken'];
    }

    getCurrentUserType(): string {
        return this.currentUser["userType"];
    }
    getCurrentUserId(): string {
        return this.currentUser['userId'];
    }

    getTotalQuestionViewsToday(): number {
        return this.totalQuestionsViewsRemaining;
    }

    setCurrentUserToken(token: string) {
        this.currentUser.token = token;
        this.setCurrentUser(this.currentUser);

    }

    setTotalQuestionViewsToday(views:number) {
        this.totalQuestionsViewsRemaining = views;
    }


}