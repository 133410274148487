import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material';

@Component({
  selector: 'tags-modal',
  templateUrl: './tags-modal.component.html',
  styleUrls: ['./tags-modal.component.css']
})
export class TagsModalComponent {
  tagsList = [];
  selectedTagsForQues = {};

  constructor(@Inject(MAT_DIALOG_DATA) public data: any) {
    this.tagsList = data.allTags;
    this.selectedTagsForQues = data.selectedTagsForQues;
  }

  getUpdatedName(name) {
    return name.toLowerCase().replaceAll('_', ' ');
  }
}
