import { Injectable } from '@angular/core';
import { UserAuthService } from '../services/userauth.service';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router, Route } from '@angular/router';
import { Observable } from 'rxjs';
import { USER_TYPE } from '../constants/app.constant';

@Injectable()
export class UsmAuthGuardService implements CanActivate {

  constructor(private _authService: UserAuthService, private _router: Router) {
  }

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {

    if (this._authService.isAuthenticated() && this._authService.verifyUserType(USER_TYPE.SUPER_ADMIN)) {
      return true;
    }
    //sign out user
    this._authService.signOut();
    return false;
  }

}