import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'sort'
})
export class SortByPipe implements PipeTransform {

  transform(list: any[], sortBy: string, sortDir: number): any[] {
    if (list.length && !!sortBy) {
      return list.sort((a, b) => {
        return (a[sortBy] - b[sortBy]) * sortDir;
      })
    }
    return list;
  }

}
