import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class APIService {
    constructor(private http: HttpClient) { }

    get(url: string) {
        return this.http.get(url);
    }
    post(url: string, body?: any, headers?: any) {
        return this.http.post(url, body, headers)

    }
    put(url: string, body?: any) {
        return this.http.put(url, body);
    }
    delete(url: string, body?: any) {
        return this.http.delete(url, body);
    }
}