import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-user-comments',
  templateUrl: './user-comments.component.html',
  styleUrls: ['./user-comments.component.css']
})
export class UserCommentsComponent implements OnInit {

  @Input() comments: Array<any>;
  constructor() { }

  isReplying: boolean = false;
  replyingTo: string | number = '';
  ngOnInit() {
  }

  onReplying(commentId) {
    this.isReplying = true;
    this.replyingTo = '';
  }

  onDelete() {
    
  }

}
