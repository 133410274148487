
export interface IPresentation {
    questionId: string;
    title: string;
    tutorId: string;
    rating: number;
    difficulty: string;
    videoLinks: string;
    editedVideoLinks: string;
    whiteBoardPages: Array<String>;
    isPresented: boolean;
}

export class Presentation implements IPresentation {
    questionId: string;
    title: string;
    tutorId: string;
    rating: number;
    difficulty: string;
    editedVideoLinks: string;
    videoLinks: string;
    whiteBoardPages: Array<String>;
    isPresented: boolean;
    constructor(questionId: string, tutorId: string, isPresented: boolean, rating?: number, difficulty?: string) {
        this.questionId = questionId;
        this.isPresented = isPresented;
        this.tutorId = tutorId;
        this.rating = rating || 0;
        this.difficulty = difficulty || '';
        this.videoLinks = '';
        this.editedVideoLinks = '';
        this.whiteBoardPages = [];
        this.title = '';
        this.isPresented = false;
    }
}