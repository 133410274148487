import { Component, Inject } from '@angular/core';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material';

@Component({
  selector: 'categories',
  templateUrl: './categories.component.html',
  styleUrls: ['./categories.component.css']
})
export class CategoriesComponent {
  categories: Array<any> = [];
  selectDiscipline: boolean = false;
  catDisplay: Array<string> = [];
  constructor(@Inject(MAT_DIALOG_DATA) public data: any) {

    this.categories = data;
  }

  ngOnInit() {
    this._setCategoryDisplay();
  }


  optionCheckChange($event) {
    console.log(this.categories);
    this._setCategoryDisplay();
   
  }

  private _setCategoryDisplay(){
    this.catDisplay =  this.categories['discipline'].concat(this.categories['examType']).concat(this.categories['organSystem']).filter(cat=>cat.selected === true);

  }

  selectAllDiscipline() {
    for (let type in this.categories)
      this.categories[type].forEach(cat => {
        cat.selected = this.selectDiscipline ? true : false;
      });
  }
  
}
