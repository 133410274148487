import { Component, OnInit, OnChanges, Input, Output, EventEmitter } from '@angular/core';

import { EventManager } from '../../services/event-manager.service';
@Component({
  selector: 'option-list',
  templateUrl: './option-list.component.html',
  styleUrls: ['./option-list.component.css']
})
export class OptionListComponent implements OnInit, OnChanges {
  @Input() optionList;
  @Input() isPresentationMode: boolean;
  @Input() label: string;
  @Input() selectionLabel: string;
  @Input() presentationMode: boolean;
  @Input() title: string = '';
  @Input() isReadOnly: boolean = false;
  @Output() optionChange: EventEmitter<any> = new EventEmitter<any>();
  public rightAnswer = '';
  optionCount: number = 0;
  alphabet = 'abcdefghijklmnopqrstuvwxyz';
  constructor(private evtMgr: EventManager) {

  }

  ngOnInit() {

  }
  ngOnChanges(changes) {
    this.optionList = changes.optionList.currentValue ? changes.optionList.currentValue : [];
    this.isPresentationMode = (changes.isPresentationMode && changes.isPresentationMode.currentValue) ? changes.isPresentationMode.currentValue : false;
    this.label = (changes.label && changes.label.currentValue) ? changes.label.currentValue : 'Options';
    this.selectionLabel = (changes.selectionLabel && changes.selectionLabel.currentValue) ? changes.selectionLabel.currentValue : 'Correct Answer:';
    this.optionCount = this.optionList.optionList.length;
    this.presentationMode = (changes.presentationMode && changes.presentationMode.currentValue) ? changes.presentationMode.currentValue : false;
    this.title = (changes.title && changes.title) ? changes.title.currentValue : '';
  }


  addOptionField(event) {
    event.preventDefault();
    this.optionList.optionList = [...this.optionList.optionList, { val: `Option ${++this.optionCount}` }];
  }

  removeOption(index, event) {
    event.preventDefault();
    this.optionList.optionList = index !== this.optionList.optionList.length - 1 ?
      [...this.optionList.optionList.slice(0, index), ...this.optionList.optionList.slice(index + 1)] :
      [...this.optionList.optionList.slice(0, index)];
    this.optionCount--;
  }

  onOptionChange(option) {
    this.optionChange.emit({ selectedOption: option });
  }
}
