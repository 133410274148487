import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material';
import { MatButtonToggleModule } from '@angular/material';
import { MatButtonModule } from '@angular/material';
import { MatCheckboxModule } from '@angular/material';
import { MatDialogModule } from '@angular/material';
import { MatIconModule } from '@angular/material';
import { MatInputModule } from '@angular/material';
import { MatProgressSpinnerModule } from '@angular/material';
import { MatProgressBarModule } from '@angular/material';
import { MatRadioModule } from '@angular/material';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatListModule } from '@angular/material/list';
import { CategoriesComponent } from '../../components/categories/categories.component';
import { QuestionPostEditComponent } from '../../components/question-post-edit/question-post-edit.component';
import { QuestionPostRoutingModule } from './question-post-routing.module';
import { QuestionPreviewComponent } from '../../components/question-preview/question-preview.component';
import { MatCardModule } from '@angular/material/card';
import { MatMenuModule } from '@angular/material/menu';
import { MatTooltipModule } from '@angular/material/tooltip';
import { AgGridModule } from 'ag-grid-angular';
import { MatExpansionModule } from '@angular/material/expansion';
import { NgImageSliderModule } from 'ng-image-slider';
import { CommentsModule } from '../comments/comments.module';
//PIPES
import { TrimPipe } from '../../pipes/./trim.pipe';

//components
import { FileUploadComponent } from '../../components/file-upload/file-upload.component';
import { OptionListComponent } from '../../components/option-list/option-list.component';
import { ReferenceLinksComponent } from '../../components/reference-links/reference-links.component';
import { QuestionViewEditComponent } from '../../components/question-view-edit/question-view-edit.component';
import { CategoriesModalComponent } from '../../components/categories-modal/categories-modal.component';
import { PresentationLogComponent } from '../../components/presentation-log/presentation-log.component';
import { SharedModule } from '../shared-module/shared-module';
import { RatingComponent } from '../../components/rating/rating.component';
import { PresentationComponent } from '../../components/presentation/presentation.component';
import { MiniTopicComponent } from '../../components/mini-topic/mini-topic.component';
import { MiniTopicLogComponent } from '../../components/mini-topic-log/mini-topic-log.component';
import { ImageViewerComponent } from '../../components/image-viewer/image-viewer.component';
import { ImagePreviewComponent } from '../../components/image-preview/image-preview.component';
import { TagsModalComponent } from '../../components/tags-modal/tags-modal.component';

//directives
import { DropFileDirective } from '../../directives/drop-file.directive';
import { QuestionsearchPipe } from '../../pipes/questionsearch.pipe';
import { SortByPipe } from '../../pipes/sortBy.pipe';

const MATERIAL_MODULES = [
  CommonModule,
  FormsModule,
  ReactiveFormsModule,
  QuestionPostRoutingModule,
  MatAutocompleteModule,
  MatGridListModule,
  MatButtonModule,
  MatCheckboxModule,
  MatInputModule,
  MatProgressBarModule,
  MatProgressSpinnerModule,
  MatCardModule,
  MatMenuModule,
  MatTooltipModule,
  // These modules include providers.
  MatButtonToggleModule,
  MatDialogModule,
  MatIconModule,
  MatRadioModule,
  MatListModule,
  MatExpansionModule,
  NgImageSliderModule
];

@NgModule({
  declarations: [
    QuestionPostEditComponent,
    CategoriesComponent,
    FileUploadComponent,
    QuestionViewEditComponent,
    OptionListComponent,
    ReferenceLinksComponent,
    CategoriesModalComponent,
    DropFileDirective,
    QuestionPreviewComponent,
    RatingComponent,
    PresentationComponent,
    PresentationLogComponent,
    MiniTopicComponent,
    MiniTopicLogComponent,
    ImageViewerComponent,
    TrimPipe,
    QuestionsearchPipe,
    SortByPipe,
    ImagePreviewComponent,
    TagsModalComponent
  ],
  entryComponents: [PresentationComponent, PresentationLogComponent, MiniTopicComponent,
     MiniTopicLogComponent, ImageViewerComponent, TagsModalComponent],
  imports: [...MATERIAL_MODULES, AgGridModule.withComponents([]), SharedModule, CommentsModule],
  exports: MATERIAL_MODULES
})
export class QuestionPostModule { }
