import { Component, Output, Input, EventEmitter } from '@angular/core';
import { ImageManagerService } from './../../services/image-manager.service';
import { ImageViewerComponent } from '../image-viewer/image-viewer.component';
import { MatDialog } from '@angular/material';

interface FileList{
    [x: string]: File;
}

@Component({
    selector: 'file-upload',
    templateUrl: './file-upload.component.html',
    styleUrls: ['./file-upload.component.css']
})
export class FileUploadComponent {

    @Input() fileInfo: string[] = [''];
    @Input() isEditMode: boolean;
    files: FileList={};
    fileList = [];
    @Output() fileSelected = new EventEmitter<any>();
    @Input() imageFormData: FormData;
    @Input() isReadOnly: boolean = false;
    imageUrl: string = "";

    constructor(private imgSvc: ImageManagerService, private matDialog: MatDialog) {

    }
    ngOnInit() {

    }
    ngOnChanges(changes) {
        this.imageFormData = (changes.imageFormData && changes.imageFormData.currentValue) ? changes.imageFormData.currentValue : {};
        this.fileInfo = (changes.fileInfo && changes.fileInfo.currentValue)
            ? changes.fileInfo.currentValue : ["Upload or Drop an Image"];
        // this.fileInfo && this.displayImage();
        // this.isEditMode = changes.isEditMode.currentValue;
    }

    displayImage() {
        if (this.fileInfo[0] === "Upload or Drop an Image" || !this.isEditMode) {
            this.imageUrl = '';
            return;
        }
        this.imgSvc.fetchImage(this.fileInfo).subscribe(
            (success: any) => {
                this.imageUrl = success;
            },
            error => {
                this.imageUrl = '';
            }
        );
    }

    downloadImage() {
        if (this.fileInfo[0] === "Upload or Drop an Image") return false;
        this.imgSvc.downloadImage(this.fileInfo);
    }

    onClick(event) {
        event.preventDefault();
        let uploadButton = document.getElementById('fileUpload') as HTMLElement;
        uploadButton.dispatchEvent(new MouseEvent('click', event));
    }

    private convertImageToFormData(imageFile: File) {
        const file: File = imageFile;
        const formData: FormData = new FormData();
        formData.append('file', file);
        return formData;

    }

    //accepts multiple files to be uploaded
    getFile(event: any) {

        const { target: { files: fileList } } = event;
        let index = 0;
        if (this.fileInfo[0] === 'Upload or Drop an Image') {
            this.fileInfo = [];
        }

        const fileListArr = [];
        const fileNameArr = [];
        for (let file of fileList) {
            if (file.type.indexOf('image') > -1) {
                const newName = file.name.replace(/(\.[\w\d_-]+)$/i, `_${new Date().getTime()}$1`);
                const copyFile = new File([file], newName, {
                    type: file.type,
                    lastModified: file.lastModified
                });
                // this.fileInfo.push(newName);
                fileNameArr.push(newName);
                fileListArr.push(copyFile);
                index++;
            }
        }
        // this.fileInfo = [].concat(this.fileInfo); //to trigger change detection
        if(fileNameArr.length){
            this.fileSelected.emit({
                fileNames: fileNameArr,
                fileData: fileListArr
            });
        }
    }

    getDroppedFile(fileList: any) {
        this.getFile({ target: { file: fileList } })
    }

    previewImage(event) {

        const dialogRef = this.matDialog.open(ImageViewerComponent, {
            width: '50%',
            data: {
                url: this.imageUrl
            },
            disableClose: false
        });

        //on dialogue close go to previous state
        dialogRef.afterClosed().subscribe(image => {

        });
        event.preventDefault();
    }

}
