import { Component, OnInit, Inject, Optional, Output, EventEmitter } from '@angular/core';
import { PresentationService } from '../../services/presentation.service';
import { MAT_DIALOG_DATA } from '@angular/material';
import { AlertService } from '../../services/alert.service';


@Component({
  selector: 'app-presentation-log',
  templateUrl: './presentation-log.component.html',
  styleUrls: ['./presentation-log.component.css']
})
export class PresentationLogComponent implements OnInit {
  qId: string;
  columnDefs = [];
  modalData: any;
  rowData: any;
  @Output() presentationUpdated = new EventEmitter<any>();
  constructor(@Optional() @Inject(MAT_DIALOG_DATA) public data: any,
    private presentationSvc: PresentationService,
    private alertService: AlertService
  ) {
    this.columnDefs = [

      { headerName: 'PresentationId', field: '_id', sortable: true, filter: true },
      { headerName: 'Title', field: 'title', sortable: true, filter: true },
      { headerName: 'TutorId', field: 'tutorId', sortable: true, filter: true },
      { headerName: 'IsPresented', field: 'isPresented', sortable: true, filter: true },
      { headerName: 'QuestionId', field: 'questionId', sortable: true, filter: true },
      { headerName: 'Difficulty', field: 'difficulty', sortable: true, filter: true },
      { headerName: 'Rating', field: 'rating', sortable: true, filter: true },
      {
        headerName: 'VideoLinks', field: 'videoLinks', sortable: true, filter: true,
        cellRenderer: function (params) {
          const link = params.value;
          const viewLink = link ? `<a href="${link}" target="_blank">Zoom Recording link</a>` : 'No Links Found';
          return viewLink;
        }
      },
      {
        headerName: 'WhiteBoardLinks',
        field: 'whiteBoardPages', sortable: true,
        filter: true
      },
      {
        headerName: 'EditableVideoLinks',
        field: 'editedVideoLinks', sortable: true,
        filter: true,
        hide: false,
        editable: true,
        cellRenderer: this.cellRender,
        onCellValueChanged: this.updatePresentationCell.bind(this)
      },
      {
        headerName: "Posted",
        field: "posted",
        width: 100,
        cellRenderer: this.cellRender,
        hide: true,
        cellEditor: 'agRichSelectCellEditor',
        cellEditorParams: {
          cellRenderer: this.cellRender,
          values: [
            'Yes',
            'No'
          ]
        },
        editable: true
      },
      {
        headerName: 'Comments',
        field: 'comments', sortable: true,
        filter: true,
        hide: false,
        editable: true,
        cellEditor: 'agLargeTextCellEditor',
        cellRenderer: this.cellRender,
        onCellValueChanged: this.updatePresentationCell.bind(this)
      },
      { headerName: 'Date/Time', field: 'updatedAt', sortable: true, filter: true },
      {
        headerName: 'Usable',
        field: 'usable',
        sortable: true,
        // editable: true,
        filter: true,
        cellClass: 'no-border',
        suppressNavigable: true,
        //cellEditor: "agSelectCellEditor",
        cellEditorParams: {
          values: [true, false]
        },
        //onCellValueChanged: this.updatePresentationCell.bind(this)
      }
    ];
    this.modalData = data;
  }

  private cellRender(params) {
    const { value, colDef: { field } } = params;
    const eVidCellVal = value ?
      ((field === 'editedVideoLinks') ? `<a href="${value}" target="_blank">${value}</a>` : value)
      : 'Double click to Edit';
    return eVidCellVal;
  }

  private updatePresentationCell(evt) {
    const { data: { _id }, newValue, colDef: { field } } = evt;
    let presUpdateobj = {};
    if (field === 'comments') presUpdateobj = { comments: newValue }
    else if (field === 'editedVideoLinks') presUpdateobj = { editedVideoLinks: newValue }
    else if (field === 'usable') presUpdateobj = { usable: newValue }
    newValue && this.presentationSvc.updatePresentation(_id, presUpdateobj)
      .subscribe(data => {
        this.presentationUpdated.emit(evt);
        this.alertService.notifyUser({ status: true, message: "Successfully Updated EditVidLinks" })
      },
        err => this.alertService.notifyUser({ status: false, message: "Failed Updation of EditVidLinks" }))
  }

  ngOnInit() {
    this.qId = this.modalData ? this.modalData.qId : '';
    if (this.qId)
      this.rowData = this.presentationSvc.getPresentationsViaQId(this.qId);
  }

}
