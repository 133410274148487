import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators, NgForm } from '@angular/forms';
import { MiniTopicService } from '../../services/mini-topic.service';
import { AlertService } from '../../services/alert.service';
@Component({
  selector: 'app-mini-topic',
  templateUrl: './mini-topic.component.html',
  styleUrls: ['./mini-topic.component.css']
})
export class MiniTopicComponent implements OnInit {
  miniTopicForm: FormGroup;
  title: String = '';
  constructor(private minTopicSvc: MiniTopicService, private alertService: AlertService) { }

  ngOnInit() {
    this.miniTopicForm = new FormGroup({
      topicControl: new FormControl('', [Validators.required]),
    });
  }

  onSubmit(event) {
    event.preventDefault();
    this.minTopicSvc.createMiniTopic({ title: this.title }).subscribe((res) => {
      this.alertService.notifyUser({ status: true, message: "Successfully added mini-topic " });
      this.resetForm(event.target);
    },
      (err) => {
        this.alertService.notifyUser({ status: false, message: "failed to add mini-topic " })
      });
  }

  resetForm(form) {
    form.reset();
  }

}
