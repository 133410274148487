import { Component } from '@angular/core';
import { Question } from './../../models/question.model';
import { QuestionService } from '../../services/question.service';
import { UserService } from '../../services/user.service';
import { ActivatedRoute, } from "@angular/router";
import { ImageManagerService } from "../../services/image-manager.service";

@Component({
    selector: 'question-preview',
    templateUrl: './question-preview.component.html',
    styleUrls: ['./question-preview.component.css']
})

export class QuestionPreviewComponent {

    fontsize: any = '20px';
    fontValue: number = 20;
    selectedItem: any = '';

    ActiveLarge: boolean = false;
    ActiveSmall: boolean = false;
    ActiveNormal: boolean = true;
    public user: any;
    public question: Question;
    public questionImageUrl: String;
    public isExpEnabled: boolean = false;
    public expLabel: string = 'Show Explanation';
    alphabet = 'abcdefghijklmnopqrstuvwxyz';
    previewImages: any = [];

    constructor(private questionService: QuestionService,
        private userSvc: UserService,
        private router: ActivatedRoute,
        private imgManager: ImageManagerService) {

    }

    ngOnInit() {
        const quesId = this.router.snapshot.paramMap.get("qId");
        this.user = this.userSvc.getCurrentUser();
        this.questionService.getQuestionWrtId(quesId)
            .subscribe(
                (result) => {
                    this.question = result;
                    this.previewImages = (this.question && this.question.image) ? this.question.image : [];
                    // this.imgManager.fetchImage(this.question.image).subscribe(
                    //     (success: any)=>{
                    //         this.questionImageUrl = success;
                    //     },
                    //     error =>{
                    //         this.questionImageUrl = '';
                    //     }
                    // );
                },
                (err) => console.log('error in getting question')
            );
    }


    showExplanation() {
        this.isExpEnabled = !this.isExpEnabled;
        this.expLabel = this.isExpEnabled ? 'Hide Explanation' : 'Show Explanation';
    }
    SmallFontSize(value) {
        this.fontValue = this.fontValue - 5;
        this.fontsize = this.fontValue + 'px';
        this.ActiveStatus(false, false, true);
    }

    LargeFontSize(value) {
        this.fontValue = this.fontValue + 5;
        this.fontsize = this.fontValue + 'px';
        this.ActiveStatus(true, false, false);
    }

    NormalFont() {
        this.fontValue = 20;
        this.fontsize = this.fontValue + 'px';
        this.ActiveStatus(false, true, false);
    }

    ActiveStatus(l, n, s) {
        this.ActiveLarge = l;
        this.ActiveNormal = n;
        this.ActiveSmall = s;
    }
}