import { Injectable } from '@angular/core';
import { APIService } from './api.service';
import { API } from '../constants/app.constant';
import { catchError, map } from 'rxjs/operators';
import { Question } from '../models/question.model';
import { UtilityService } from '../services/utility.service';
@Injectable({
    providedIn: 'root'
})
export class QuestionService {
    constructor(private api: APIService, private utilSvc: UtilityService) { }

    storeQuestions(questions) {
        return this.api.post(API.BASE_URL + API.QUESTION.CREATE, questions);
    }

    getQuestions(userId) {
        return this.api.get(API.BASE_URL + API.QUESTION.QUESTIONS + '?userId=' + userId).pipe(
            map((resp) => resp['result'].data)
        );
    }

    formatQuestionToUpload(question) {
        const categories = { discipline: [], organSystem: [], examType: [] };

        for (let type in question.categories) {
            if (type != '_id') question.categories[type].filter((category) => { if (category.selected) categories[type].push(category.name) });
        }

        const postBody = {
            userId: question.userId,
            qId: question.qId,
            stem: question.stem,
            imageList: question.image.imageList,
            explanation: question.explanation,
            additionalExplanation: question.additionalExplanation,
            option: question.option,
            refLinks: question.reference.refList,
            videoLinks: question.videoLinks.refList,
            googleDocLinks: question.googleDocLinks.refList,
            categories: categories,
            contentFeedback: question.contentFeedback,
            comment: question.comment
        }

        return postBody;
    }

    editQuestion(qId: any, question) {
        return this.api.put(API.BASE_URL + API.QUESTION.UPDATE + qId, question)
    }

    getQuestionWrtId(qId) {
        return this.api.get(API.BASE_URL + API.QUESTION.QUESTION_BY_ID + qId).pipe(
            map((resp) => {
                if (resp['status'] === '1') {
                    const question = resp['result'].data;
                    const parsedQuestion = new Question(question.userId, question._id, question.stem,
                        question.imageList, question.option, question.explanation, question.additionalExplanation,
                        { refList: question.refLinks }, { refList: question.videoLinks }, { refList: question.googleDocLinks },
                        question.categories, question.userEmail, new Date(question.createdAt), question.presentationCount,
                        question.contentFeedback, question.comment);
                    this.utilSvc.makeArrayElementChangeDetectable(parsedQuestion.reference.refList);
                    this.utilSvc.makeArrayElementChangeDetectable(parsedQuestion.option.optionList);

                    return parsedQuestion;
                } else {
                    throw new Error(resp['result'].message)
                }
            }),
            catchError((err) => {
                throw err;
            })
        );
    }

    parseQuestions(questions) {
        const questionList = [];
        for (let question of questions) {
            questionList.push(
                new Question(question.userId,
                    question._id, question.stem,
                    { imageList: question.imageList },
                    question.option,
                    question.explanation,
                    question.additionalExplanation,
                    { refList: question.refLinks || [] },
                    { refList: question.videoLinks || [] },
                    { refList: question.googleDocLinks || [] },
                    question.categories, question.userEmail,
                    new Date(question.createdAt),
                    question.presentationCount,
                    question.usablePresentationCount,
                    question.contentFeedback,
                    question.comment
                )
            )
        }
        return questionList;
    }

    uploadImage(image: FormData | FormData[]) {
        const headers = new Headers();
        /** In Angular 5, including the header Content-Type can invalidate your request */
        headers.append('Content-Type', 'multipart/form-data');
        headers.append('Accept', 'application/json');
        return this.api.post(API.BASE_URL + API.IMAGE.UPLOAD, image, { headers, responseType: 'text' });
    }

    removeQuestionById(userId, qId) {
        return this.api.post(API.BASE_URL + API.QUESTION.REMOVE_QUESTION_ + '?userId=' + userId + '&qId=' + qId).pipe(
            map((resp) => {
                if (resp['status'] === '1') {
                    return resp['result'].message;
                } else {
                    throw new Error(resp['result'].message)
                }

            }),
            catchError((err) => {
                throw err;
            }));
    }

    validateQuestion(question) {
        const isCatValid = (question.categories.discipline.filter(x => x.selected == true).length > 0
            || question.categories.organSystem.filter(x => x.selected == true).length > 0)
            && question.categories.examType.filter(x => x.selected == true).length > 0;
        return isCatValid;
    }

    getQuestionsViewedToday(userId) {
        return this.api.get(API.BASE_URL + API.USER.GET_QUESTION_VIEWS + '/' + userId).pipe(
            map((resp) => resp['result'].data)
        );
    }

    updateQuestionsViewedToday(userId) {
        return this.api.put(API.BASE_URL + API.USER.UPDATE_QUESTION_VIEWS + '/' + userId);
    }

    getAllTags() {
        return this.api.get(API.BASE_URL + API.TAGS.GET).pipe(
            map((resp) => resp['result'].data)
        );
    }

    getQuesTags(quesId) {
        return this.api.get(API.BASE_URL + API.TAGS.GET_QUES_TAG + '/' + quesId).pipe(
            map((resp) => resp['result'].message)
        );
    }

    updateQuesTags(quesId, params) {
        return this.api.post(API.BASE_URL + API.TAGS.UPDATE_QUES_TAG + '/' + quesId, params);
    }
}