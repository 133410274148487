import { Injectable } from '@angular/core';
import { APIService } from './api.service';
import { UserService } from './user.service';
import { API } from '../constants/app.constant';
import { catchError, retry, map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { User } from '../models/user.model';
import { Router } from '@angular/router';
import { DefaultUrlHandlingStrategy } from '@angular/router/src/url_handling_strategy';
@Injectable()
export class UserAuthService {
    constructor(private api: APIService,
        private userService: UserService,
        private router: Router) { }

    private parseData(resp: any, isDataStoreNeeded: boolean = false) {
        if (resp['status'] === '1') {
            if (isDataStoreNeeded) {
                this.setUserData(resp);
                return resp;
            } else {
                return resp['result'].message;
            }

        } else {
            throw new Error(resp['result'].message)
        }
    }
    userLogin(body: any, isUSM?: boolean) {
        let url = API.BASE_URL + API.USER.SIGNIN;
        if (isUSM) url += '?type=usm';
        return this.api.post(url, body).pipe(
            map(resp => this.parseData(resp, true)),
            catchError((err) => {
                throw new Error(err.message || err);
            })
        )
    }

    userSignUp(body: any) {
        return this.api.post(API.BASE_URL + API.USER.SIGNUP, body).pipe(
            map(resp => this.parseData(resp, true)),
            catchError((err) => {
                throw new Error(err);
            })
        )
    }

    verifyUser(userId) {
        return this.api.put(API.BASE_URL + API.USER.VERFIY_USER + userId).pipe(
            map(resp => this.parseData(resp)),
            catchError((err) => {
                if (typeof err === 'object') throw new Error(err.message);
                throw new Error(err);
            })
        )
    }


    resetPassword(userId: string, body: any) {
        return this.api.put(API.BASE_URL + API.USER.RESET_PASSWORD + '/' + userId, body).pipe(
            map(resp => this.parseData(resp)),
            catchError((err) => {
                throw new Error(err.message);
            })
        )

    }
    resetPasswordLink(email: string) {
        return this.api.get(API.BASE_URL + API.USER.RESET_PASSWORD_LINK + '/' + email).pipe(
            map(resp => this.parseData(resp)),
            catchError((err) => {
                throw new Error(err.message);
            })
        )

    }

    getAllUsers() {
        return this.api.get(API.BASE_URL + API.USER.GET_USERS).pipe(
            map(resp => {
                if (resp['status'] === '1') {
                    return resp['result'].data;
                } else {
                    throw new Error(resp['result'].message)
                }
            }),
            catchError((err) => {
                throw new Error(err.message);
            })
        )
    }

    setUserData(data) {
        let userData = data['result'].data;
        if (userData) {
            this.userService.setCurrentUser(new User(userData.userId, userData.email, userData.type, userData.token, userData.refreshToken));
            return data;
        } else {
            const err = data['result'].message || 'Error Occured'
            throw new Error(err);
        }

    }

    public getAccessToken(): string {
        return this.userService.getCurrentUserToken();
    }

    public verifyUserType(type: string): boolean {
        return this.userService.getCurrentUserType() === type;
    }
    public isAuthenticated(): boolean {
        const token = this.getAccessToken();
        return !!token;
    }
    public signOut(): void {
        this.userService.removeCurrentUser();
        this.router.navigate(['/'], { replaceUrl: true });
    }

    public refreshAccessToken() {
        const URL = API.BASE_URL + API.USER.REFRESH_TOKEN;
        return this.api.post(URL, {
            refreshToken: this.userService.getCurrentUserRefreshToken(),
            userId: this.userService.getCurrentUserId()
        }).pipe(
            map((data: any) => {
                let token = data.result.message.auth ? data.result.message.token : null;
                this.userService.setCurrentUserToken(token)
                return token;
            })
        )
    }
}