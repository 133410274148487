import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoginComponent } from '../../components/login/login.component';
import { SignupComponent } from '../../components/signup/signup.component';
import { ResetPasswordComponent } from '../../components/reset-password/reset-password.component';
import { VerifyUserComponent } from '../../components/verify-user/verify-user.component';
import { UsmAuthGuardService } from '../../guards/usm-auth-guard.service';

const routes: Routes = [{
    path: '',
    component: LoginComponent,
    children: [{ path: 'user-management', component: LoginComponent }]
},
{
    path: 'login',
    component: LoginComponent,
    children: [{ path: 'user-management', component: LoginComponent }]
},
{
    path: 'signup',
    component: SignupComponent
},
{
    path: 'reset-password/:userId',
    component: ResetPasswordComponent
},
{
    path: 'reset-password',
    component: ResetPasswordComponent
},
{
    path: 'verify-user/:userId',
    component: VerifyUserComponent
},
{ path: 'user-management-portal', canActivate: [UsmAuthGuardService], loadChildren: '../user-management/user-management.module#UserManagementModule' }

];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class AccountRoutingModule { }
