import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { QuestionPostEditComponent } from '../../components/question-post-edit/question-post-edit.component';
import { QuestionViewEditComponent } from '../../components/question-view-edit/question-view-edit.component';
import { CategoriesComponent } from '../../components/categories/categories.component';
import { QuestionPreviewComponent } from '../../components/question-preview/question-preview.component';
import { AuthGuard } from '../../guards/auth-guard.service';

const routes: Routes = [
    {

        path: 'post-question', component: QuestionPostEditComponent, canActivate: [AuthGuard] , children: [
            { path: 'categories', component: CategoriesComponent }
        ]

    }, {
        path: 'view-edit', component: QuestionViewEditComponent, canActivate: [AuthGuard] , children: [
            { path: 'categories', component: CategoriesComponent }
        ]
    },
    {
        path: 'preview-question/:qId', component: QuestionPreviewComponent , canActivate: [AuthGuard]
    }

];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class QuestionPostRoutingModule { }
