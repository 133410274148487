import { Injectable } from '@angular/core';
import { APIService } from './api.service';
import { API } from '../constants/app.constant';
import { AlertService } from '../services/alert.service';

@Injectable({
  providedIn: 'root'
})
export class ImageManagerService {

  constructor(private api: APIService, private alertSrvice: AlertService,) {

  }

  fetchImage(imageName: any) {
    const url = API.BASE_URL + API.IMAGE.GET_IMAGE + '?image=' + imageName;
    return this.api.get(url);
  }

  downloadImage(imageName) {
    const url = API.BASE_URL + API.IMAGE.DOWNLOAD_IMAGE + '?image=' + imageName;
    // return this.api.get(url).pipe(
    //   map(resp => {
    //     return resp
    //   }),
    //   catchError((err) => {
    //     throw new Error(err);
    //   })
    // )
    var link = document.createElement('a');
    link.href = url;
    link.download = url.substr(url.lastIndexOf('/') + 1);
    link.click();
  }
}
