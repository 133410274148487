import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material';
import { DIFFICULTY_TYPE } from '../../constants/app.constant';
import { Presentation } from '../../models/presentation.model';
import { PresentationService } from '../../services/presentation.service';
import { UserService } from '../../services/user.service';
import { yearsPerPage } from '@angular/material/datepicker/typings/multi-year-view';

@Component({
  selector: 'app-presentation',
  templateUrl: './presentation.component.html',
  styleUrls: ['./presentation.component.css']
})
export class PresentationComponent implements OnInit {
  qId: string;
  title: string;
  tutorId: string;
  presentationInfo: any;
  isPresented: boolean;
  difficultyOptions: { optionList: Array<any>, correctAnswer: string } = {
    optionList: [],
    correctAnswer: ''
  };
  label: string = 'Select difficulty for student';
  selectionLabel: string = 'Difficulty selected as';
  presentation: Presentation;
  Presented: any = {
    YES: 'Yes',
    NO: 'No'
  };

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
    private presentationSvc: PresentationService,
    private userSvc: UserService, ) {
    this.presentationInfo = data;
  }

  ngOnInit() {
    this.qId = this.presentationInfo.qId;
    this.title = this.presentationInfo.title;
    this.tutorId = this.userSvc.getCurrentUserId();
    this.presentation = new Presentation(this.qId, this.tutorId, this.isPresented);
    this.difficultyOptions = this.presentationSvc.prepareDiffData(this.difficultyOptions, DIFFICULTY_TYPE);

  }

  onRatingClick({ rating }) {
    this.presentation.rating = rating;
  }

  onDifficultySelection({ selectedOption }) {
    const { val } = selectedOption;
    this.presentation.difficulty = val;
  }

  onOptionChange(evt){
    console.log(evt);
    this.presentation.isPresented = this.isPresented = (this.Presented.YES === evt.value);
  }
}
