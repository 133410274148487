import { Directive, EventEmitter, ElementRef, Renderer2, HostListener, Output, Input } from '@angular/core';

@Directive({
    selector: '[dropFile]',
})
export class DropFileDirective {
    
    @Output() private fileSelected: EventEmitter<File> = new EventEmitter();

    constructor(private renderer: Renderer2, private hostElement: ElementRef) {
    }

    @HostListener('drop', ['$event']) public onDrop(event) {
        event.preventDefault();
        event.stopPropagation();
        console.log(event)
        if (event.dataTransfer.files.length > 0 && this._hasValidFiles(event.dataTransfer.files)) {
            this.fileSelected.emit(event.dataTransfer.files);
        }
        this.renderer.removeClass(this.hostElement.nativeElement, 'dotted-border');
    }

    @HostListener('dragover', ['$event']) public onDragOver(event) {
        event.preventDefault();
        event.stopPropagation();
        this.renderer.addClass(this.hostElement.nativeElement, 'dotted-border');
    }

    @HostListener('dragleave', ['$event']) public onDragLeave(event) {
        event.preventDefault();
        event.stopPropagation();
        this.renderer.removeClass(this.hostElement.nativeElement, 'dotted-border');
    }

    private _hasValidFiles(files): boolean {
        //If not has invalid
        return !files.some((file) => (file.type === null || file.type === undefined));
    }
}