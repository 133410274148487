import { Component, OnInit, OnChanges, Input, Output, EventEmitter } from '@angular/core';
import { CategoriesComponent } from '../categories/categories.component';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { Location } from '@angular/common';
import { CATEGORY } from '../../constants/categories.constant';
@Component({
  selector: 'categories-modal',
  templateUrl: './categories-modal.component.html',
  styleUrls: ['./categories-modal.component.css']
})
export class CategoriesModalComponent implements OnInit {
  @Input() categoryList;
  @Output() categoriesSelected = new EventEmitter<any>();
  constructor(private matDialog: MatDialog, private location: Location) { }

  ngOnInit() {

  }

  ngOnChanges(changes) {
    this.categoryList = changes.categoryList.currentValue;
  }


  //TODO: move to service

  private initCategories(type){
    return  CATEGORY[type].map(category => {
      return { selected: false, name: category }
    });
  }
  private checkIfCategorySelected(category: string, type: string) {
    return this.categoryList[type].find(cat => cat === category)
  }

  private formatCategoriesForViewEdit(category, type) {
    if (this.checkIfCategorySelected(category, type))
      return { selected: true, name: category };
    else
      return { selected: false, name: category };
  }
  openCategories() {
    //TODO: move to constants
    const categories = {};

    for (let type in CATEGORY) {

      //if categories were parsed earlier
      if (this.categoryList[type][0] && !!this.categoryList[type][0].name) {
        categories[type] = this.categoryList[type];
        continue;
      }

      //if categories are selected 
      if (this.categoryList[type] && this.categoryList[type].length) {

        categories[type] = CATEGORY[type].map((category, c_index) => this.formatCategoriesForViewEdit(category, type));
      } else {
        categories[type] = this.initCategories(type);
      }
    }
    const dialogRef = this.matDialog.open(CategoriesComponent, {
      width: '60%',
      data: categories,
      disableClose: true
    });

    //on dialogue close go to previous state
    dialogRef.afterClosed().subscribe(result => {
      this.categoryList = result;
      this.categoriesSelected.emit(this.categoryList);
      this.gotoPrevState();
    });
  }

  gotoPrevState() {
    this.location.back();
  }

}
