import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { Router, ActivatedRoute, RouterState } from '@angular/router';
import { UserAuthService } from '../../services/userauth.service';
import { AlertService } from '../../services/alert.service';
import {USER_TYPE} from '../../constants/app.constant';
@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

    showSpinner: boolean = false;
    username: string;
    password: string;
    isUserManagementLogin: boolean = false;
    constructor(private actrouter: ActivatedRoute, private router: Router, private location: Location, private userAuthService: UserAuthService, private alertService: AlertService) {

    }

    ngOnInit() {
        this.isUserManagementLogin = this.actrouter.snapshot['_routerState'].url.match(/user-management/) ? true : false;
    }
    onLoginClick() {
        if (!!this.username && !!this.password) {
            this.showSpinner = true;
            this.userAuthService.userLogin({ email: this.username, password: this.password }, this.isUserManagementLogin)
                .subscribe(
                    data => {
                        let user = data.result.data;
                        if (user.type === USER_TYPE.SUPER_ADMIN && this.isUserManagementLogin) {
                            this.router.navigate(["user-management-portal"], { replaceUrl: true })
                        }
                        else if (user.type === USER_TYPE.EDITOR) {
                            this.router.navigate(["view-edit"], { replaceUrl: true })
                        }
                        else {
                            this.router.navigate(["post-question"], { replaceUrl: true });
                        }

                    }, err => {
                        this.alertService.notifyUser({ status: false, message: err.message });
                        this.showSpinner = false;
                    });
        }
    }
    gotoPrevState() {
        this.location.back();
    }
}
