
export interface User {
    userId: any;
    username: string;
    userType: string;
    token: string;
    refreshToken: string;
}

export class User implements User {
    constructor(userId: string, username: string,  userType: string, token: string, refreshToken: string) {
        this.userId = userId;
        this.userType = userType;
        this.username = username;
        this.token = token;
        this.refreshToken = refreshToken;
    }
}