import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'questionsearch'
})
export class QuestionsearchPipe implements PipeTransform {
  private filterWrtCategories(question, searchText, tokens?) : boolean{
    for(const cat in question.categories){
      if(cat === '_id') continue;
      for(let catItem of question.categories[cat]){
        if(catItem.toLowerCase().includes(searchText) || tokens && tokens.some((text)=>catItem.toLowerCase().includes(text))){
          return true;
        }
      }
    }
    return false;
  }
  transform(items: any[], searchField: string, searchText: string): any[] {
    if (!items) return [];
    if (!searchText) return items;
    searchText = searchText.toLowerCase().trim();
    searchText = this.ommitSpecialChars(searchText);
    let tokens = searchText.split('+');
    tokens = tokens.map((str)=>this.ommitSpecialChars(str));
    
    if (tokens.length > 1) {
     var fil = items.filter(it => this.searchOnQuestionFields(it, tokens[0], searchField))
     var fil2=   fil.filter(it => tokens.slice(1,tokens.length).some(token => { 
          return this.searchOnQuestionFields(it, token, searchField) }))
          return fil2;
    }

    return items.filter(item => this.searchOnQuestionFields(item, searchText, searchField));
    
  }

  private searchOnQuestionFields(item, searchTerm, searchField?) {
    const searchRes = item[searchField].toLowerCase().includes(searchTerm);
    //search wrt questionId
    const searchWrtQuestionId = item.qId.toLowerCase().includes(searchTerm);
    // search wrt categories
    const searchWrtCategories = this.filterWrtCategories(item, searchTerm);
    // search wrt user email
    const searchWrtUserEmail = item.userEmail.includes(searchTerm);
    // search wrt explanantion 
    const searchWrtExplanation = item.explanation.toLowerCase().includes(searchTerm);
    return searchRes || searchWrtQuestionId || searchWrtCategories || searchWrtUserEmail || searchWrtExplanation;
  }
  private ommitSpecialChars(stringText) {
    const strLen = stringText.length;
    return stringText[strLen - 1].match(/[,\.]/g) ? stringText.substr(0, strLen - 1) : stringText;
  }

}
