import { Component, OnInit, SecurityContext } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-comments-section',
  templateUrl: './comments-section.component.html',
  styleUrls: ['./comments-section.component.css']
})
export class CommentsSectionComponent implements OnInit {

  new_comment: string = '';
  constructor(private sanitizer: DomSanitizer) { }

  ngOnInit() {
  }

  onComment(event) {
    let safe_comment = this.sanitizer.sanitize(SecurityContext.HTML,this.new_comment);
    event.stopPropagation();
    event.preventDefault();
    //post safe comment
  }

}
