import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import {
    MatFormFieldModule,
    MatCardModule,
    MatButtonModule,
    MatProgressSpinnerModule,
    MatRadioModule,
    MatInputModule,
    MatIconModule,
    MatDialogModule,
    MatSelectModule
} from '@angular/material';
import { LoginComponent } from '../../components/login/login.component';
import { SignupComponent } from '../../components/signup/signup.component';
import { ResetPasswordComponent } from '../../components/reset-password/reset-password.component';
import { AccountRoutingModule } from './account-routing.module';
import { VerifyUserComponent } from '../../components/verify-user/verify-user.component';
import { UsmAuthGuardService } from '../../guards/usm-auth-guard.service';

let MODULE_IMPORTS = [
    BrowserModule,
    FormsModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatCardModule,
    MatButtonModule,
    MatProgressSpinnerModule,
    AccountRoutingModule,
    ReactiveFormsModule,
    MatButtonModule,
    MatRadioModule,
    MatInputModule,
    MatProgressSpinnerModule,
    MatCardModule,
    // These modules include providers.
    MatDialogModule,
    MatIconModule,
    MatSelectModule
];

@NgModule({
    declarations: [
        LoginComponent,
        SignupComponent,
        ResetPasswordComponent,
        VerifyUserComponent
    ],
    imports: MODULE_IMPORTS,
    exports: MODULE_IMPORTS,
    providers: [UsmAuthGuardService]
})

export class AccountModule { }
