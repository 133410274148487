import { Injectable } from '@angular/core';
import { APIService } from './api.service';
import { API } from '../constants/app.constant';
import { catchError, map } from 'rxjs/operators';
import { forkJoin } from 'rxjs';
@Injectable({
  providedIn: 'root'
})
export class PresentationService {

  constructor(private api: APIService) { }

  preparePresentation(presentationObj: any, questionId: string) {
    const createP$ = this.createPresentation(presentationObj),
      updatePCount$ = this.updatePresentationCount(questionId);

    return forkJoin(createP$, updatePCount$);
  }



  createPresentation(presentationObj) {
    return this.api.post(API.BASE_URL + API.PRESENTATION.CREATE, presentationObj).pipe(
      map((resp) => {
        if (resp['status'] === '1') {
          return resp['result'].data;
        } else {
          throw new Error(resp['result'].message)
        }

      }),
      catchError((err) => {
        throw err;
      })
    )
  }

  public updatePresentation(pId: string, presentationObj: any) {
    return this.api.put(API.BASE_URL + API.PRESENTATION.UPDATE + '/' + pId, presentationObj).pipe(
      map((resp) => {
        if (resp['status'] === '1') {
          return resp['result'].data;
        } else {
          throw new Error(resp['result'].message)
        }

      }),
      catchError((err) => {
        throw err;
      })
    )
  }

  getPresentationsViaQId(qId) {
    return this.api.get(API.BASE_URL + API.PRESENTATION.GET_PRES_BY_TUTORID + qId).pipe(
      map((resp) => {
        if (resp['status'] === '1') {
          return resp['result'].data;
        } else {
          throw new Error(resp['result'].message)
        }
      }),
      catchError((err) => {
        throw err;
      })
    );
  }

  public prepareDiffData(difficultyOptions, DIFFICULTY_TYPE): { optionList: Array<any>, correctAnswer: string } {
    difficultyOptions.optionList = DIFFICULTY_TYPE.map((val) => {
      return { 'val': val };
    });
    //difficultyOptions.correctAnswer = DIFFICULTY_TYPE[0];
    // this.presentationSvc.createPresentation(this.presentation);
    return difficultyOptions;
  }

  createPLogRowData(presentationList, colHeaders) {
    return presentationList.map((presentation, index) => {

    })
  }

  public parseEditedVidLink(params) {
    const eVidLink = params.value;
    const eVidCellVal = eVidLink ? eVidLink : 'Double Click to Edit Link';
    console.log(params, params.value);
    return eVidCellVal;
  }

  updatePresentationCount(qId) {
    return this.api.put(API.BASE_URL + API.QUESTION.UPDATE_PRESENTATION_COUNT + qId).pipe(
      map((resp) => {
        if (resp['status'] === '1') {
          return resp['result'].message;
        } else {
          throw new Error(resp['result'].message)
        }
      }),
      catchError((err) => {
        throw err;
      })
    )


  }

  getUsablePresentations() {
    return this.api.get(API.BASE_URL + API.PRESENTATION.GET_USABLE_COUNT).pipe(
      map((resp) => {
        if (resp['status'] === '1') {
          return resp['result'].message;
        } else {
          throw new Error(resp['result'].message)
        }
      }),
      catchError((err) => {
        throw err;
      })
    )
  }

}
