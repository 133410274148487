import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material';
import { ConfirmationModalComponent } from '../components/confirmation-modal/confirmation-modal.component';

export interface AlertMessage {
    status: boolean;
    message: string;
}
@Injectable()
export class AlertService {
    constructor(private matDialog: MatDialog) { }

    notifyUser(status: boolean | AlertMessage, onCloseCallback?) {
        //notifies user about question submission status.
        const dialogRef = this.matDialog.open(ConfirmationModalComponent, {
            width: '20%',
            data: status
        });

        //on dialogue close go to previous state
        dialogRef.afterClosed().subscribe(result => {
            if (onCloseCallback)
                onCloseCallback();
        });
    }

    confirm(data: Array<{ name: string, model: boolean | number | string }>, status?, onCloseCallback?) {
        const dialogRef = this.matDialog.open(ConfirmationModalComponent, {
            width: '25%',
            data: [data,status]
        });

        //on dialogue close go to previous state
        dialogRef.afterClosed().subscribe(result => {
            if (onCloseCallback)
                onCloseCallback(result);
        });
    }

}