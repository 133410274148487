import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from "@angular/router";
import { UserAuthService } from '../../services/userauth.service';

@Component({
  selector: 'app-verify-user',
  templateUrl: './verify-user.component.html',
  styleUrls: ['./verify-user.component.css']
})
export class VerifyUserComponent implements OnInit {
  message: string
  constructor(private route: ActivatedRoute,
    private userAuthService: UserAuthService) { }

  ngOnInit() {
    this.route.paramMap.subscribe(params => {
      const userId = params.get("userId");
      this.userAuthService.verifyUser(userId)
        .subscribe(
          data => {
            this.message = 'Suceessfully verified mail';
          }, err => {
            this.message = 'Could not verify mail. Please Try again!';
          });
    })
  }





}
