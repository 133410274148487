import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material';
import { MatButtonToggleModule } from '@angular/material';
import { MatButtonModule } from '@angular/material';
import { MatCheckboxModule } from '@angular/material';
import { MatDialogModule } from '@angular/material';
import { MatIconModule } from '@angular/material';
import { MatInputModule } from '@angular/material';
import { MatProgressSpinnerModule } from '@angular/material';
import { MatProgressBarModule } from '@angular/material';
import { MatRadioModule } from '@angular/material';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatListModule } from '@angular/material/list';
import { MatCardModule } from '@angular/material/card';
import { MatMenuModule } from '@angular/material/menu';
import { MatTooltipModule } from '@angular/material/tooltip';
import { SharedModule } from '../shared-module/shared-module';
import { MatExpansionModule } from '@angular/material/expansion';
import { CommentsSectionComponent } from '../../components/comments-section/comments-section.component';
import { UserCommentsComponent } from '../../components/user-comments/user-comments.component';

const MATERIAL_MODULES = [
  CommonModule,
  FormsModule,
  ReactiveFormsModule,
  MatAutocompleteModule,
  MatGridListModule,
  MatButtonModule,
  MatCheckboxModule,
  MatInputModule,
  MatProgressBarModule,
  MatProgressSpinnerModule,
  MatCardModule,
  MatMenuModule,
  MatTooltipModule,
  // These modules include providers.
  MatButtonToggleModule,
  MatDialogModule,
  MatIconModule,
  MatRadioModule,
  MatListModule,
  MatExpansionModule
];

@NgModule({
  declarations: [CommentsSectionComponent, UserCommentsComponent],
  imports: [
    ...MATERIAL_MODULES,
    SharedModule
  ],
  exports: [MATERIAL_MODULES, CommentsSectionComponent, UserCommentsComponent]
})
export class CommentsModule { }
