import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material';

const CONFIRM_MESSGE = 'Done!', REJECT_MESSAGE = 'Ooops!! :(';
@Component({
    selector: 'confirmation-modal',
    templateUrl: './confirmation-modal.component.html',
    styleUrls: ['./confirmation-modal.component.css']
})
export class ConfirmationModalComponent {
    confirmation: any;
    confirmationMessage = CONFIRM_MESSGE;
    actions: Array<{ name: string, model: boolean | number | string, comment?: string, hasComment?: boolean }>;
    hasComment: any;

    constructor(@Inject(MAT_DIALOG_DATA) public data: any) {
        this.actions = null, this.confirmation = null;

        if (data.length) {
            this.actions = data[0];
            this.hasComment = this.actions[0].hasComment;
            this.confirmation = data[1];
        } else {
            this.confirmation = data;
        }

        this.setConfirmationMesage();
    }

    setConfirmationMesage() {
        if (this.confirmation !== undefined && typeof this.confirmation === 'boolean') {
            this.confirmationMessage = this.confirmation ? CONFIRM_MESSGE : REJECT_MESSAGE;
        } else if (this.confirmation !== undefined && typeof this.confirmation === 'object') {
            this.confirmationMessage = this.confirmation.message;
        } else if (this.confirmation !== undefined && typeof this.confirmation === 'string') {
            this.confirmationMessage = this.confirmation;
        }
    }
}
