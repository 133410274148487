import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MenuBarComponent } from '../../components/menu-bar/menu-bar.component';
import { MatButtonToggleModule } from '@angular/material';
import { MatButtonModule } from '@angular/material';
import { MatIconModule } from '@angular/material';
import { MatInputModule } from '@angular/material';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatExpansionModule } from '@angular/material/expansion';

const MATERIAL_MODULES = [
    CommonModule,
    MatButtonModule,
    MatInputModule,
    MatMenuModule,
    MatTooltipModule,
    // These modules include providers.
    MatButtonToggleModule,
    MatIconModule,
    MatListModule,
    MatExpansionModule
];

@NgModule({
    imports: MATERIAL_MODULES,
    declarations: [
        MenuBarComponent
    ],
    exports: [
        MenuBarComponent
    ]
})
export class SharedModule { }