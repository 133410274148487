import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { QuestionPostModule } from './modules/question-post/question-post.module';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatFormFieldModule } from '@angular/material/form-field';
import { FormsModule } from '@angular/forms';
import { MatCardModule, MatButtonModule, MatProgressSpinnerModule } from '@angular/material';
import { ConfirmationModalComponent } from './components/confirmation-modal/confirmation-modal.component';
import { AccountModule } from './modules/account/account.module';
import { UtilityService } from './services/utility.service';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { APIService } from './services/api.service';
import { UserAuthService } from './services/userauth.service';
import { AlertService } from './services/alert.service';
import { UserService } from './services/user.service';
import { RefreshTokenInterceptor } from './core/interceptors/refresh-token.interceptor';
import { AuthGuard } from './guards/auth-guard.service';
import { LoaderComponent } from './components/loader/loader.component';
import {SharedModule} from './modules/shared-module/shared-module';

let MODULE_IMPORTS = [
  BrowserModule,
  FormsModule,
  SharedModule,
  HttpClientModule,
  BrowserAnimationsModule,
  AppRoutingModule,
  QuestionPostModule,
  MatFormFieldModule,
  MatCardModule,
  MatButtonModule,
  MatProgressSpinnerModule,
  AccountModule
];

@NgModule({
  declarations: [
    AppComponent,
    ConfirmationModalComponent,
    LoaderComponent
  ],
  entryComponents: [ConfirmationModalComponent],
  imports: MODULE_IMPORTS,
  providers: [{ provide: HTTP_INTERCEPTORS, useClass: RefreshTokenInterceptor, multi: true }, UtilityService, APIService, UserService, UserAuthService, AlertService, AuthGuard],
  bootstrap: [AppComponent]
})

export class AppModule { }
