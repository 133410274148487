import { Component, OnInit } from '@angular/core';
import { Question } from './../../models/question.model';
import { QuestionService } from '../../services/question.service';
import { UtilityService } from '../../services/utility.service';
import { UserService } from '../../services/user.service';
import { AlertService } from '../../services/alert.service';
import { CONFIRM_CHANGES } from '../../constants/app.constant';
import { MatDialog } from '@angular/material';
import { PresentationComponent } from '../../components/presentation/presentation.component';
import { PresentationLogComponent } from '../../components/presentation-log/presentation-log.component';
import { PresentationService } from '../../services/presentation.service';
import { NO_QUESTIONS } from '../../constants/app.constant';
import { Router } from '@angular/router';
import { USER_TYPE, MAX_QUESTION_VIEW_COUNT } from '../../constants/app.constant';
import { LoaderService } from '../../services/loader.service';
import { MiniTopicComponent } from '../../components/mini-topic/mini-topic.component';
import { MiniTopicLogComponent } from '../../components/mini-topic-log/mini-topic-log.component';

@Component({
  selector: 'app-question-view-edit',
  templateUrl: './question-view-edit.component.html',
  styleUrls: ['./question-view-edit.component.css'],
  providers: [QuestionService]
})
export class QuestionViewEditComponent implements OnInit {
  public questionList: Array<Question> = [];
  public questionBinding: Question;
  public selectedQuestionIndex: number = 0;
  public user;
  public userId:string;
  public userType: string = '';
  public searchText: string = '';
  public isAdmin: boolean = false;
  public sortControl = {
    sortDir: -1,
    sortBy: 'createdAt'
  };

  constructor(private questionService: QuestionService,
    private presentationSvc: PresentationService,
    private utilityService: UtilityService,
    private userSvc: UserService,
    private alertSrvice: AlertService,
    private matDialog: MatDialog,
    private _router: Router,
    private loaderService: LoaderService) { }

  ngOnInit() {
    this.user = this.userSvc.getCurrentUser();
    this.userType = this.user.userType;
    this.userId = this.user.userId;
    this.isAdmin = (this.userType === USER_TYPE.ADMIN) || (this.userType === USER_TYPE.SUPER_ADMIN);
    console.log(this.userType);
    this.getQuestionsViewedToday();
    this.getQuestions(this.user.userId);
    this.selectedQuestionIndex = 0;
    (this.questionList && this.questionList.length != -1) && this.editQuestion(this.selectedQuestionIndex);

  }

  getQuestionsViewedToday(){
    this.questionService.getQuestionsViewedToday(this.user.userId).subscribe((result:any)=>{
      this.userSvc.setTotalQuestionViewsToday(result.views);
    });
  }

  updateQuestionsViewedToday() {
    const totalQuestionsViewsRemaining = this.userSvc.getTotalQuestionViewsToday();
    if (totalQuestionsViewsRemaining === 0) {
      this.alertSrvice.notifyUser({ status: true, message: 'Max question view limit exhausted' })
      return;
    }
      this.questionService.updateQuestionsViewedToday(this.user.userId).subscribe(() => {
       // this.userSvc.setTotalQuestionViewsToday(totalQuestionsViewsRemaining - 1);
    })
  }

  getQuestions(userId) {
    this.loaderService.show();

    this.questionService.getQuestions(userId).subscribe((questions) => {
      // this.questionList = [resp];
      this.loaderService.hide();
      if (questions.length < 1) {
        //notify user that they have no questions to be shown and then redirect them to add question screen
        this.alertSrvice.notifyUser({ status: false, message: NO_QUESTIONS }, () => {
          this._router.navigate(['/post-question']);
        });
      }
      this.questionList = this.questionService.parseQuestions(questions);
      this.questionList = this.questionList.sort((q1, q2) => (q2.createdAt.getTime() - q1.createdAt.getTime()) )
      this.parseQuestionData();
      this.editQuestion(this.selectedQuestionIndex);
    });

  }

  parseQuestionData() {
    for (let i in this.questionList) {
      this.utilityService.makeArrayElementChangeDetectable(this.questionList[i].reference.refList);
      this.utilityService.makeArrayElementChangeDetectable(this.questionList[i].videoLinks.refList)
      this.utilityService.makeArrayElementChangeDetectable(this.questionList[i].option.optionList);
      this.utilityService.makeArrayElementChangeDetectable(this.questionList[i].googleDocLinks.refList);
    }
  }


  editQuestion(index: number, question?: Question) {
    console.log("INDEX:", index, this.questionList.length);

    if (question && this.questionBinding && this.questionBinding.qId !== question.qId && !this.isAdmin) {
      this.updateQuestionsViewedToday();
    }
    this.questionBinding = null;
    this.questionBinding = question || this.questionList[index];
    this.selectedQuestionIndex = index;
  }

  presentQuestion(question: Question) {
    const { qId, userId, stem } = question;
    const dialogRef = this.matDialog.open(PresentationComponent, {
      width: '55%',
      height: '100%',
      data: {
        qId: qId,
        userId: userId,
        title: stem
      },
      disableClose: false
    });

    //on dialogue close go to previous state
    dialogRef.afterClosed().subscribe(presentation => {
      presentation && this.presentationSvc.preparePresentation(presentation, qId)
        .subscribe(
          (result) => {
            ++question.presentationCount;
            this.alertSrvice.notifyUser({ status: true, message: 'Presentation Created Successfully' });
          },
          (err) => this.alertSrvice.notifyUser({ status: true, message: 'Error in creating Presentation' })
        );

    });
  }

  showPresentationbLog(question: Question) {
    const { qId, userId } = question;
    const dialogRef = this.matDialog.open(PresentationLogComponent, {
      width: '50%',
      data: {
        qId: qId,
        userId: userId
      },
      disableClose: false
    });

    dialogRef.componentInstance.presentationUpdated.subscribe((evt) => {
      const { data, colDef: { field } } = evt;
      if (field === 'usable') {
        this.questionList = this.questionList.map((question) => {
          if (question.qId === data.questionId && data.usable === 'true')
            return { ...question, usablePresentationCount: question.usablePresentationCount + 1 };
          else if (question.qId === data.questionId && data.usable === 'false') {
            return { ...question, usablePresentationCount: question.usablePresentationCount - 1 };
          }
          return question;
        })
      }
    })
    //on dialogue close go to previous state
    dialogRef.afterClosed().subscribe(presentation => {
      dialogRef.componentInstance.presentationUpdated.unsubscribe();
    });
  }

  deleteQuestion({ index }) {
    event.preventDefault();
    let confirmData = [{ name: 'Delete', model: true }, { name: 'Discard', model: false }];

    this.alertSrvice.confirm(confirmData, CONFIRM_CHANGES, (result) => {
      if (result) {
        const { userId, qId } = this.questionList[index];
        this.loaderService.show();
        this.questionService.removeQuestionById(this.user.userId, qId)
          .subscribe(
            (result) => {
              this.loaderService.hide();
              this.alertSrvice.notifyUser({ status: true, message: 'Successfully Deleted' });
              this.questionList.splice(index, 1);
              this.editQuestion(0);
            },
            (err) => {
              this.loaderService.hide();
              this.alertSrvice.notifyUser({ status: true, message: 'Error in deletion' })
            }
          );
      }
      else { }
    });


  }

  openMiniTopic() {
    const dialogRef = this.matDialog.open(MiniTopicComponent, {
      width: '55%',
      height: '80%',
      disableClose: false
    });
  }
  openMiniTopicLog() {
    const dialogRef = this.matDialog.open(MiniTopicLogComponent, {
      width: '55%',
      height: '80%',
      disableClose: false
    });
  }

}
