import { Component, OnInit, Input } from '@angular/core';
import { UserService } from '../../services/user.service';
import { Router } from '@angular/router';

@Component({
  selector: 'menu-bar',
  templateUrl: './menu-bar.component.html',
  styleUrls: ['./menu-bar.component.css']
})
export class MenuBarComponent implements OnInit {
  @Input() userInfo;
  @Input() isUSMmode;
  @Input() isEditMode;
  email: string;
  userType: string;
  constructor(private router: Router,
    private userService: UserService) { }

  ngOnInit() {
    this.email = this.userInfo.username;
    this.userType = this.userInfo.userType;
  }

  onViewEdit(){
    this.router.navigate(["/view-edit"]);
  }
  onPostEdit(){
    this.router.navigate(["/post-question"])
  }
  onLogout(){
    this.router.navigate([""]);
    this.userService.removeCurrentUser();
  }

}
