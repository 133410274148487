import { Injectable } from "@angular/core";
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpErrorResponse } from "@angular/common/http";
import { UserAuthService as AuthenticationService } from "./../../services/userauth.service";
import { Observable, throwError } from "rxjs";
import { BehaviorSubject } from "rxjs";
import { catchError, switchMap } from 'rxjs/operators';
@Injectable()
export class RefreshTokenInterceptor implements HttpInterceptor {
	private refreshTokenInProgress = false;
	// Refresh Token Subject tracks the current token, or is null if no token is currently
	// available (e.g. refresh pending).
	private refreshTokenSubject: BehaviorSubject<any> = new BehaviorSubject<any>(
		null
	);
	constructor(public authService: AuthenticationService) { }

	intercept(
		request: HttpRequest<any>,
		next: HttpHandler
	): Observable<HttpEvent<any>> {
		// We don't want to refresh token for some requests like login or refresh token itself
		if (
			request.url.includes("token") ||
			request.url.includes("signin") ||
			request.url.includes("signup") ||
			request.url.includes("resetPassword")) {
			return next.handle(request).pipe(catchError((error) => {
				return throwError(error.message);
			}));
		}

		if (this.authService.isAuthenticated()) {
			request = this.addAuthenticationToken(request);
		}

		return next.handle(request).pipe(
			catchError((error: any) => {

				// If error status is different than 401 we want to skip refresh token
				// So we check that and throw the error if it's the case
				if (error.status !== 401) {
					return throwError(error.message);
				} else {
					return this.authService
						.refreshAccessToken().pipe(switchMap((token: any) => {
							//When the call to refreshToken completes we reset the refreshTokenInProgress to false
							// for the next time the token needs to be refreshed
							this.refreshTokenInProgress = false;

							return next.handle(this.addAuthenticationToken(request));
						}),
							catchError((err: any) => {
								this.refreshTokenInProgress = false;

								this.authService.signOut();
								return throwError(error.message);
							}))
				}

			})
		);
	}


	addAuthenticationToken(request) {
		// Get access token from Local Storage
		const accessToken = this.authService.getAccessToken();

		// If access token is null this means that user is not logged in
		// And we return the original request
		if (!accessToken) {
			return request;
		}

		// We clone the request, because the original request is immutable
		return request.clone({
			setHeaders: {
				Authorization: accessToken
			}
		});
	}
}